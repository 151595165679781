import React from 'react';
import FlexibleContainer from './flexibleContainer';
import './App.css'

function App() {
        return (
                <div className="app-container">
                        <h1 className="header">Charles "Buddy" Crews Gallery</h1>
                        <FlexibleContainer />
                </div>
                
                
        );
}


export default App;
