import React from 'react';
import ImageGallery from 'react-image-gallery';
import './gallery.css'
import 'react-image-gallery/styles/scss/image-gallery.scss'
import "react-image-gallery/styles/css/image-gallery.css";

import img1 from './assets/066.jpg'
import img2 from './assets/087.jpg'
import img3 from './assets/147.jpg'
import img4 from './assets/Binder1_Page_01.jpg'
import img5 from './assets/Binder1_Page_02.jpg'
import img6 from './assets/Binder1_Page_03.jpg'
import img7 from './assets/Binder1_Page_04.jpg'
import img8 from './assets/Binder1_Page_05.jpg'
import img9 from './assets/Binder1_Page_06.jpg'
import img10 from './assets/Binder1_Page_07.jpg'
import img11 from './assets/Binder1_Page_08.jpg'
import img12 from './assets/Binder1_Page_09.jpg'
import img13 from './assets/Binder1_Page_10.jpg'
import img14 from './assets/Binder1_Page_11.jpg'
import img15 from './assets/Binder1_Page_12.jpg'
import img16 from './assets/Binder1_Page_13.jpg'
import img17 from './assets/Binder1_Page_14.jpg'
import img18 from './assets/Binder1_Page_15.jpg'
import img19 from './assets/Binder1_Page_16.jpg'
import img20 from './assets/Binder1_Page_17.jpg'
import img21 from './assets/Binder1_Page_18.jpg'
import img22 from './assets/Binder1_Page_19.jpg'
import img23 from './assets/Binder1_Page_20.jpg'
import img24 from './assets/Binder1_Page_21.jpg'
import img25 from './assets/Binder1_Page_22.jpg'
import img26 from './assets/Binder1_Page_23.jpg'
import img27 from './assets/Binder1_Page_24.jpg'
import img28 from './assets/Binder1_Page_25.jpg'
import img29 from './assets/Binder1_Page_26.jpg'
//import img30 from './assets/Binder1_Page_27.jpg'
import img31 from './assets/Binder1_Page_28.jpg'
import img32 from './assets/Binder1_Page_29.jpg'
import img33 from './assets/Binder1_Page_30.jpg'
import img34 from './assets/Binder1_Page_31.jpg'
import img37 from './assets/Binder1_Page_32_fixed.jpg'
import img38 from './assets/Binder1_Page_33.jpg'
import img39 from './assets/Binder1_Page_34.jpg'
import img40 from './assets/Binder1_Page_35.jpg'
import img41 from './assets/Binder1_Page_36.jpg'
import img42 from './assets/Binder1_Page_37.jpg'
import img43 from './assets/Binder1_Page_38.jpg'
import img44 from './assets/Binder1_Page_39.jpg'
import img45 from './assets/Binder1_Page_40.jpg'
import img46 from './assets/IMG_20210227_074522.jpg'
import img47 from './assets/IMG_20210227_074523.jpg'
import img48 from './assets/IMG_20210227_074525.jpg'
import img49 from './assets/IMG_20210227_074526.jpg'
import img50 from './assets/IMG_20210227_074527.jpg'
import img51 from './assets/IMG_20210227_074531.jpg'
import img52 from './assets/IMG_20210227_074532.jpg'
import img53 from './assets/IMG_20210227_074535.jpg'
import img54 from './assets/IMG_20210302_105142.jpg'
import img55 from './assets/IMG_20210302_105143.jpg'
import img56 from './assets/IMG_20210302_105144.jpg'
import img57 from './assets/IMG_20210302_105145.jpg'
import img58 from './assets/IMG_20210302_105146 (1).jpg'
import img59 from './assets/IMG_20210302_105146.jpg'
import img60 from './assets/IMG_20210302_105147.jpg'
import img61 from './assets/IMG_20210302_105148.jpg'
import img62 from './assets/IMG_20210302_105149.jpg'
import img63 from './assets/IMG_20210302_105150.jpg'
import img64 from './assets/IMG_20210302_105151.jpg'

const IMAGES =
    [{ original: img1, thumbnail: img1 },
        { original: img2, thumbnail: img2 },
        { original: img3, thumbnail: img3 },
        { original: img4, thumbnail: img4 },
        { original: img5, thumbnail: img5 },
        { original: img6, thumbnail: img6 },
        { original: img7, thumbnail: img7 },
        { original: img8, thumbnail: img8 },
        { original: img9, thumbnail: img9 },
        { original: img10, thumbnail: img10 },
        { original: img11, thumbnail: img11 },
        { original: img12, thumbnail: img12 },
        { original: img13, thumbnail: img13 },
        { original: img14, thumbnail: img14 },
        { original: img15, thumbnail: img15 },
        { original: img16, thumbnail: img16 },
        { original: img17, thumbnail: img17 },
        { original: img18, thumbnail: img18 },
        { original: img19, thumbnail: img19 },
        { original: img20, thumbnail: img20 },
        { original: img21, thumbnail: img21 },
        { original: img22, thumbnail: img22 },
        { original: img23, thumbnail: img23 },
        { original: img24, thumbnail: img24 },
        { original: img25, thumbnail: img25 },
        { original: img26, thumbnail: img26 },
        { original: img27, thumbnail: img27 },
        { original: img28, thumbnail: img28 },
        { original: img29, thumbnail: img29 },
        { original: img31, thumbnail: img31 },
        { original: img32, thumbnail: img32 },
        { original: img33, thumbnail: img33 },
        { original: img34, thumbnail: img34 },
        { original: img37, thumbnail: img37 },
        { original: img38, thumbnail: img38 },
        { original: img39, thumbnail: img39 },
        { original: img40, thumbnail: img40 },
        { original: img41, thumbnail: img41 },
        { original: img42, thumbnail: img42 },
        { original: img43, thumbnail: img43 },
        { original: img44, thumbnail: img44 },
        { original: img45, thumbnail: img45 },
        { original: img46, thumbnail: img46 },
        { original: img47, thumbnail: img47 },
        { original: img48, thumbnail: img48 },
        { original: img49, thumbnail: img49 },
        { original: img50, thumbnail: img50 },
        { original: img51, thumbnail: img51 },
        { original: img52, thumbnail: img52 },
        { original: img53, thumbnail: img53 },
        { original: img54, thumbnail: img54 },
        { original: img55, thumbnail: img55 },
        { original: img56, thumbnail: img56 },
        { original: img57, thumbnail: img57 },
        { original: img58, thumbnail: img58 },
        { original: img59, thumbnail: img59 },
        { original: img60, thumbnail: img60 },
        { original: img61, thumbnail: img61 },
        { original: img62, thumbnail: img62 },
        { original: img63, thumbnail: img63 },
        { original: img64, thumbnail: img64 }]

function Gallery(width) {
    return (
        <div className="gallery-container">
            <div style={{width:width}}>
                <ImageGallery
                    autoPlay={true}
                    slideDuration={1400}
                    slideInterval={6000}
                    items={IMAGES}
                    thumbnailPosition="bottom"
                    additionalClass='gallery'
                    />
            </div>
        </div>
    );
}


export default Gallery;
